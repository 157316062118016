// App.js
import React from 'react';
import './App.css';
import Header from './components/Header/header';
import MainPage from './components/MainPage/MainPage';
import ContactUs from './components/Contact/ContactUs'; // Import ContactUs component
import ScrollToTopButton from './components/ScrollToTop/ScrollToTopButton'; // Import ScrollToTopButton component
import Footer from './components/Footer/footer'; // Import Footer component
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
 
function App() {
  return (
       <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </Router>
    );
}

function Home() {
  return (
    <>
      <Header />
      <MainPage />
      <ContactUs />
      <ScrollToTopButton />
      <Footer />
    </>
  );
}

export default App;
